import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import sandw1 from "./../../assets/img/formules/Sandwichs et Tartes/sandwich.png";
import tarte1 from "./../../assets/img/formules/Sandwichs et Tartes/tarte.png";
import { useState } from "react";

function SlideSandwichs() {
  const [getImg] = useState([sandw1, tarte1]);
  return (
    <>
      <Carousel
        className="carouselSalad"
        showArrows={true}
        autoPlay={true}
        infiniteLoop={true}>
        {getImg.map((img, i) => {
          return <img key={i} src={img} alt="sandwichs" />;
        })}
      </Carousel>
      {/* MODIFICATION REMI */}
      {/* MODIFICATION REMI */}
      {/* MODIFICATION REMI */}
      <p> Sandwichs / Tartes salées </p>
      <p>Poulet croustillant / Pastrami / 3 Fromages / Quiches </p>
      <p>Formule Entrée ou Dessert + Boisson </p>
      <p>Prix : 9€</p>
      {/* MODIFICATION REMI */}
      {/* MODIFICATION REMI */}
      {/* MODIFICATION REMI */}
    </>
  );
}

export default SlideSandwichs;


import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import pic1 from '../../assets/img/carouselPhotos/pic1.png'
import pic2 from '../../assets/img/carouselPhotos/pic2.png'
import pic3 from '../../assets/img/carouselPhotos/pic3.png'
import pic4 from '../../assets/img/carouselPhotos/pic4.png'
import pic5 from '../../assets/img/carouselPhotos/pic5.png'
import pic6 from '../../assets/img/carouselPhotos/pic6.png'


function SlideView() {

  const images = [{
    url : pic1,
    caption : 'im1'},
    
    {url : pic2, 
    caption : 'im2'},

    {url : pic3, 
    caption : 'im3'},

    {url : pic4, 
    caption : 'im4'},

    {url : pic5, 
    caption : 'im5'},

    {url : pic6, 
    caption : 'im6'},

]

  return (
    
    <Carousel className="carouselTeam" showArrows={true} autoPlay={true} interval={5000}  infiniteLoop={true} width={300}>
      {images.map((image, index) => (
        <div className="pictures" key={index}>
          <img className="imgTeam" src={image.url} alt={image.caption}  />
        </div>
      ))}

    </Carousel>
  )
}

export default SlideView
import Header from "./components/Header/Header";
import Content from "./components/Content/Content";
import Footer from "./components/Footer/Footer"
import Presentations from "./components/Presentation/Presentations";



function App() {
  return (
    <div className="App">
     <Header/>
     <Content/>
     <Presentations/>
     <Footer/>
    </div>
  );
}

export default App;

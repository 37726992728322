import Blocs from "../Content/Blocs";
import SlideSalade from "./SlideSalade";
import SlideSandwichs from "./SlideSandwichs";
import SlidePoke from "./SlidePoke";
import fork from "../../assets/img/fork.png";
import SlidePasta from "./SlidePasta";

function Content() {
  return (
    <div className="content d-flex flex-fill flex-column ">
      <div className="nosFormules"></div>
      <Blocs />

      <h2 className="titreFormules"> Nos formules</h2>
      <div className="formules d-flex">
        <div className="for " data-aos="flip-left">
          <SlideSalade />
        </div>
        <div className="for" data-aos="flip-left">
          <SlidePasta />
        </div>
        <div className="for" data-aos="flip-left">
          <SlideSandwichs />
        </div>
        <div className="for" data-aos="flip-left">
          <SlidePoke />
        </div>
      </div>
      <img
        className="fork"
        src={fork}
        alt="fourchette"
        data-aos="fade-left"
        data-aos-anchor="#example-anchor"
        data-aos-offset="500"
        data-aos-duration="500"></img>
    </div>
  );
}

export default Content;

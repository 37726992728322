import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const MentionLegale = () => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const modalWidth = window.innerWidth < 768 ? "90%" : "50%";

  return (
    <div>
      <button className="btn-mention" onClick={onOpenModal}>
        Mentions Légales
      </button>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        styles={{ modal: { width: modalWidth } }}>
        <h2>Mentions Légales</h2>
        <h3>INFORMATIONS</h3>
        <ul>
          <li>- Responsable de la publication : LACHIVER Rémi </li>
          <li>- Raison sociale de la société exploitante : 2RL </li>
          <li>- Téléphone : 01.43.47.44.87 </li>
          <li>- Numéro individuel d’identification de TVA : FR37841023021 </li>
          <li>- Numéro de Siret : 841023021 </li>
          <li>- Capital Social : 1000€ </li>
        </ul>
        <h3>HEBERGEUR</h3>
        <p>Nom : IONOS</p>
        <p>
          Adresse :
          <br />
          7, place de la Gare
          <br />
          BP 70109
          <br />
          57200 Sarreguemines Cedex
          <br />
          France
        </p>
        <p>
          Contact :
          <br />
          Vous pouvez joindre le service clientèle par téléphone 24h/24, 7j/7 au
          0970 808 911 (appel non surtaxé) ou par email à l’adresse suivante :
          info@ionos.fr{" "}
        </p>
        <h3>INFORMATIQUE ET LIBERTÉ</h3>
        <p>
          Tout utilisateur ayant déposé des informations directement ou
          indirectement nominatives dispose d’un droit d’accès et de
          rectification conformément à la loi n° 7817 du 6/01/78 (Informatique
          et Liberté) auprès de l’administrateur du site.
        </p>
        <h3>LIENS HYPERTEXTES</h3>
        <p>
          Les sites extérieurs à 2RL ayant un lien hypertexte avec le présent
          site ne sont pas sous contrôle de 2RL, qui décline par conséquent
          toute responsabilité quant à leur contenu. L’utilisateur est seul
          responsable de leur utilisation.
        </p>
        <h3>REPONSABILITES</h3>
        <p>
          En accédant à ce site, vous êtes informé que l’utilisation d’Internet
          nécessite le respect d’un ensemble de législations relatives notamment
          aux droits d’auteur et à la loi Informatique et Libertés. La 2RL ne
          peut encourir de responsabilité du fait d’erreurs ou d’omissions,
          l’éditeur se réservant également le droit de modifier et d’actualiser
          à tout moment les données du site.
        </p>
        <h3>COPYRIGHT</h3>
        <p>
          En application du Code Français de la Propriété Intellectuelle, la
          reproduction pour un usage autre que privé, la vente, la diffusion, la
          publication, l’adaptation ou la communication, partielles ou
          intégrales, des données (présentation, agencement du site, photos,
          textes et illustrations protégés par le droit d’auteur) sont
          strictement soumises à l’autorisation écrite et préalable des
          détenteurs des droits, ci-dessus cités.
        </p>
        <h3>INFORMATIQUE ET LIBERTÉS</h3>
        <p>
          Tout utilisateur ayant déposé des informations directement ou
          indirectement nominatives dispose d’un droit d’accès et de
          rectification conformément à la loi n° 7817 du 6/01/78 (Informatique
          et Libertés) auprès de l’administrateur du site.
        </p>
        <h3>PROTECTION DES DONNEES PERSONNELLES</h3>
        <p>
          Identité du responsable du traitement Le site est édité par la société
          exploitante dont les coordonnées sont ci-dessus. L’accès au site
          internet ainsi que l’utilisation de son contenu s’effectuent dans le
          cadre des mentions d’utilisation décrites ci-après.
        </p>
        <p>
          Nature des données personnelles Il est entendu par « donnée
          personnelle » toute information personnelle que vous êtes susceptible
          de partager à la société exploitante et qui peut permettre à cette
          dernière, directement ou indirectement, de vous identifier en tant que
          personne physique, par exemple lors d’une visite de site internet,
          d’une demande d’information, d’une réservation de chambre et autres
          prestations.
        </p>
        <p>
          Les données personnelles collectées Il peut vous être demandé de
          communiquer vos données personnelles lorsque vous naviguez sur le site
          web de la société exploitante. Le caractère obligatoire ou facultatif
          des informations demandées vous est signalé lors de la collecte par un
          astérisque « * ». Les informations demandées signalées par un
          astérisque sont obligatoires car indispensables au traitement de vos
          différentes demandes. Les autres informations sont destinées à mieux
          vous connaître, à améliorer les services qui vous sont proposés. Elles
          sont, par conséquent, facultatives. La société exploitante collecte et
          traite notamment vos nom, prénom, adresse email, adresse de
          facturation, numéro de téléphone, adresse IP, données de connexions et
          données de navigation, vos préférences et centres d’intérêts (chambre
          non-fumeur, chambre préférée, type de séjour…), vos avis en ligne.
        </p>
        <p>
          Quand vos données personnelles sont-elles collectées ? Vous naviguez
          sur le site internet (adresse IP et Cookies) et consultez des produits
          ou services ; Vous faites part à la société exploitante de vos
          observations/commentaires dans certaines rubriques, via questionnaires
          ou formulaires de contact, page officielle des réseaux sociaux de la
          société exploitante ; Vous envoyez à la société exploitante un message
          électronique ; Vous vous inscrivez à la liste de diffusion de la
          newsletter ; Vous partagez une page ou une offre par envoi d’un email
          ou par l’intermédiaire d’un réseau social ; Vous répondez à un
          questionnaire de satisfaction ; Vous effectuez une demande de devis.
        </p>
        <p>
          Le stockage des données Les informations récoltées sont stockées dans
          nos différents systèmes de réservation sur des bases de données
          sécurisées et certifiées SSL. Cette base de données comprend : le nom
          des clients, leur adresse, leurs numéros de téléphone, leur poste, le
          nom de leur entreprise et les données de leur carte de crédit. Nous
          pouvons également enregistrer d’autres informations telles que vos
          préférences concernant la chambre, les repas, les boissons et d’autres
          services ainsi que votre historique de séjour au sein de notre
          établissement.
        </p>
        <p>
          Finalité de la collecte de données par la société exploitante Au
          travers du site internet, la société exploitante collecte les données
          vous concernant qui sont strictement nécessaires aux finalités
          suivantes :
        </p>
        <ul>
          <li>
            1. Afin de gérer vos demandes d’informations et de réservations,
            ainsi que tout programme de fidélité ;
          </li>
          <br />
          <li>
            2. Pour améliorer et personnaliser les services de la société
            exploitante afin de faciliter votre navigation sur le site web ;
          </li>
          <br />
          <li>3. Pour gérer et suivre l’ensemble de la relation client ;</li>
          <br />
          <li>
            4. Gérer l’inscription aux actualités et offres promotionnelles de
            la société exploitante (newsletter) ;
          </li>
          <br />
          <li>
            5. Pour gérer toutes vos demandes d’accès selon la législation en
            vigueur{" "}
          </li>
          <br />
          <li>6. Gérer des impayés et du contentieux ;</li>
          <br />
          <li>
            7.Gérer des demandes de candidatures dans le cadre des offres
            d’emploi de la société exploitante qui ont retenu votre attention,
            ou toute demande de candidature spontanée ;
          </li>
          <br />
          <li>8. La mesure de la qualité et de la satisfaction.</li>
        </ul>
        <p>
          En l’absence de réponse aux informations obligatoires, votre demande
          ne pourra être prise en compte.
        </p>
        <p>
          Engagements de la société exploitante en matière de protection des
          données personnelles
        </p>
        <p>
          Transparence et finalité : Aucune donnée personnelle n’est ou ne sera
          collectée à votre insu. La nature facultative ou obligatoire des
          mentions à communiquer à la société exploitante dans le cadre de la
          collecte effectuée sur le site internet vous sera préalablement
          indiquée, via la présence du symbole « * » astérisque. La société
          exploitante collecte et traite vos données personnelles uniquement aux
          fins décrites dans les présentes Mentions Légales.
        </p>
        <p>
          Proportionnalité et pertinence : La société exploitante ne collecte et
          ne traite que les données personnelles nécessaires au bon traitement
          de votre demande ou à la personnalisation des services qui vous sont
          proposés.
        </p>
        <p>
          Temps de stockage des données : La société exploitante ne conserve vos
          données personnelles que pendant la période nécessaire à leur
          traitement conformément à ce qui est prévu par la loi applicable.
        </p>
        <p>
          Sécurité et confidentialité : La société exploitante s’engage à
          prendre les mesures nécessaires pour garantir la confidentialité des
          données et de ne pas divulguer à des tiers non autorisés. Nous pouvons
          être amenés à transmettre vos informations personnelles aux autorités
          locales, si cela est exigé par la loi ou dans le cadre d’une enquête
          et conformément à la réglementation locale. Comme la plupart des sites
          internet, certaines informations sont automatiquement enregistrées via
          un protocole. Ces informations incluent les adresses de protocole
          (adresse IP), type de navigateur utilisé, le fournisseur de service
          internet, les pages de référence et pages finales, le système
          d’exploitation, la date, le temps et les données Clickstream. Les
          cookies n’enregistrent pas les informations fournies par les
          utilisateurs au cours d’une réservation en ligne ou lors de
          l’enregistrement d’un compte client. Les cookies identifient votre
          navigateur plutôt que vous-même et ne peuvent suffire par eux-mêmes à
          révéler votre identité.
        </p>
        <p>
          Respect de vos droits : Vous êtes informé de vos droits
          d’interrogation, d’accès, de rectification et d’opposition pour motifs
          légitimes ainsi que de votre droit d’opposition à la prospection
          commerciale conformément aux dispositions de la loi n°78-17 du 6
          janvier 1978.
        </p>
        <p>
          Droits d’accès, rectification et opposition : Conformément à la loi n°
          78-17 du 6 janvier 1978, relative à l’Informatique, aux Fichiers et
          aux Libertés, vous bénéficiez, sur simple demande écrite, d’un droit
          d’accès et de rectification sur les informations vous concernant. Vous
          pouvez également, pour des motifs légitimes, vous opposer au
          traitement des données vous concernant. Pour exercer l’un de ces
          droits et obtenir communication des informations vous concernant, il
          suffit de vous adresser à la société exploitante dont les coordonnées
          sont accessibles en début de ces Mentions Légales.
        </p>
        <h3>COOKIES</h3>
        <p>
          Lors de la consultation du site internet, certaines informations
          relatives à la navigation de votre terminal sont susceptibles d’être
          enregistrées dans des fichiers appelés « Cookies » installés sur votre
          terminal, sous réserve des choix que vous auriez exprimés concernant
          les cookies et que vous pouvez modifier à tout moment. Un cookie est
          un petit fichier texte enregistré dans un espace dédié du disque dur
          de votre terminal, à l’occasion de la consultation d’un service en
          ligne grâce à votre logiciel de navigation. Il permet à son émetteur
          d’identifier le terminal dans lequel il est enregistré, pendant la
          durée de validité ou d’enregistrement du cookie. Les cookies que la
          société exploitante émet sont utilisés aux fins décrites ci-après,
          pendant la durée de validité du cookie concerné, sous réserve de vos
          choix qui résultent des paramètres de votre logiciel de navigation
          utilisé lors de votre visite du site internet et dont vous pouvez
          modifier le paramétrage à tout moment. Des cookies de sociétés
          partenaires (cookies tiers) sont susceptibles d’être placés dans votre
          ordinateur via les pages du site internet. Seul l’émetteur d’un cookie
          est susceptible de lire ou de modifier des informations qui y sont
          contenues. Différents types de cookies sont utilisés sur le site
          internet, ils ont des finalités différentes. Certains sont nécessaires
          à votre utilisation du site internet.
        </p>
        <p>
          Les cookies analytiques Il s’agit des cookies qui permettent à la
          société exploitante :
        </p>
        <ul>
          <li>
            - de générer des statistiques et des différents éléments composant
            le site web (rubriques et contenus visités, parcours) afin
            d’améliorer l’expérience de navigation proposée ;
          </li>
          <br />
          <li>
            - de comptabiliser le nombre total de publicités affichées par ses
            soins sur ses espaces publicitaires, d’identifier ces publicités,
            leur nombre d’affichages, le nombre de clics et informations de
            navigation.
          </li>
        </ul>
        <p>Exemple de cookies analytiques :</p>
        <ul>
          <li>_ga Analytics : enregistrement des données de navigation</li>
          <br />
          <li>__utm Analytics : enregistrement des données de navigation</li>
          <br />
          <li>
            _gac Campaign : enregistrement des données de navigation des
            campagnes publicitaires
          </li>
        </ul>
        <p>
          Les cookies et plug-in de réseaux sociaux (boutons sociaux) La société
          exploitante est susceptible d’inclure sur le site web des outils de
          partage de contenus avec d’autres personnes ou de faire connaître à
          ces autres personnes votre consultation ou votre opinion concernant un
          contenu de notre site. Tel est notamment le cas de boutons « Partager
          », « J’aime » issus de réseaux sociaux tels que Facebook, Twitter,
          etc.
        </p>
        <br />
        La société exploitante n’a aucun contrôle sur le processus employé par
        les réseaux sociaux dans la collecte d’informations.
        <ul>
          <li>Facebook Connect : connexion en utilisant son compte Facebook</li>
          <li>Instagram : partage de la page sur Instagram</li>
          <li>TripAdvisor : connexion en utilisant son compte TripAdvisor</li>
        </ul>
        <p>
          Les cookies et identifiants machine aux fins de sécurisation des
          transactions Nous vous informons que lors de votre commande, la
          société exploitante, ou ses prestataires agissant pour son compte,
          peut déposer des cookies ou collecter des informations relatives à
          votre terminal notamment les informations (attributs techniques de
          sécurité) nécessaires à la reconnaissance des équipements (ordinateur,
          tablette ou téléphone portable) afin de permettre l’identification de
          votre équipement aux fins de sécurisation des transactions dans votre
          intérêt.
        </p>
        <p>
          Les cookies publicitaires Les contenus publicitaires (graphisme,
          animations, vidéos etc.) diffusés dans les espaces publicitaires de la
          société exploitante sont susceptibles de contenir des cookies émis par
          des tiers qui peuvent permettre, pendant la durée de validité des
          cookies, de comptabiliser les clics, mais aussi de vous proposer les
          publicités les plus adaptées suivant votre comportement de navigation,
          centres d’intérêts et préférences.
        </p>
        <br />
        Le refus des cookies publicitaires n’a pas d’impact sur l’utilisation du
        site et n’entraînera pas l’arrêt de la publicité sur le site ou sur
        internet. Cela aura seulement pour effet d’afficher une publicité qui ne
        tiendra pas compte de vos centres d’intérêt ou de vos préférences.
        <br />
        Ces cookies dépendent principalement des régies publicitaires. Nous ne
        pouvons pas les lister de manière exhaustive :
        <ul>
          <li>
            - AdWords : achat de mots-clés sponsorisés affichés sur Google
          </li>
          <br />
          <li>- Facebook Custom Audience</li>
          <br />
          <li>- Publicité ciblée : enregistrement du parcours de navigation</li>
        </ul>
        <p>
          Ciblage publicitaire
          <br />
          Le site est susceptible de contenir des cookies émis par des tiers
          (agences et outils publicitaires) et de permettre à ces derniers,
          pendant la durée de validité de ce cookie de récolter des données de
          navigation relatives aux appareils se connectant sur nos sites, mais
          aussi de vous proposer des contenus publicitaires susceptibles de
          correspondre à votre précédente navigation sur le site.
          <br />
          En aucun cas cette collecte de ces données permet à ces prestataires
          de vous identifier personnellement. Vos nom, prénom, nom
          d’utilisateur, adresse postale, adresse électronique et mot de passe
          ne sont en aucun cas communiqués à des tiers sans votre consentement
          préalable et explicite.
        </p>
        <p>
          Vos choix concernant les cookies
          <br />
          Plusieurs possibilités vous sont offertes pour gérer les cookies. Tout
          paramétrage que vous pouvez entreprendre sera susceptible de modifier
          votre navigation sur internet et vos conditions d’accès à certains
          services nécessitant l’utilisation de cookies.
        </p>
        <p>
          Les choix qui vous sont offerts par votre logiciel de navigation
          <br />
          Vous pouvez personnaliser votre navigateur internet (Google Chrome,
          Internet Explorer, Mozilla Firefox etc…) de manière à ce que des
          cookies soient enregistrés dans votre terminal ou au contraire qu’ils
          soient refusés.
        </p>
        <p>
          Le refus des cookies
          <br />
          Si vous refusez l’enregistrement de cookies, certaines
          fonctionnalités, comme l’identification / connexion à votre espace
          personnel, la personnalisation de votre navigation (choix de la langue
          favorite par exemple) ne seront plus disponibles.
          <br />
          La société exploitante décline toute responsabilité pour les
          conséquences liées au fonctionnement dégradé de ses services et
          fonctionnalités dues au refus du cookie.
        </p>
        <p>
          Comment exercer vos choix, selon le navigateur que vous utilisez ?
          <br />
          Pour la gestion des cookies et de vos choix, la configuration de
          chaque navigateur est différente. Elle est décrite dans le menu d’aide
          de votre navigateur qui vous permettra de savoir de quelle manière
          modifier vos souhaits en matière de cookies.
        </p>
        <p>
          Pour Safari™ :
          <br />
          https://support.apple.com/?path=Safari/3.0/fr/9277.html ou Dans votre
          navigateur, choisissez le menu Édition / Préférences. Cliquez sur
          Sécurité. Cliquez sur Afficher les cookies. Sélectionnez les cookies
          qui contiennent le nom de la société exploitante et cliquez sur
          Effacer ou sur Tout effacer. Après avoir supprimé les cookies, cliquez
          sur Terminé.
        </p>
        <p>
          Pour Chrome™ :
          <br />
          https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en ou Ouvrez
          Google Chrome. Dans la barre d’outils du navigateur, cliquez sur Plus.
          Placez votre curseur sur Plus d’outils, puis cliquez sur Effacer les
          données de navigation. Dans la fenêtre « Effacer les données de
          navigation », cochez les cases Cookies et données d’autres sites ou
          plug-in et Images et fichiers en cache. Utilisez le menu en haut pour
          sélectionner la quantité de données que vous souhaitez supprimer.
          Sélectionnez Depuis le début pour tout supprimer. Cliquez sur Effacer
          les données de navigation.
        </p>
        <p>
          Pour Firefox™ :
          <br />
          https://support.mozilla.org/fr/kb/activer-desactiver-cookies-preferences
          ou Allez dans l’onglet « Outils » du navigateur puis sélectionnez le
          menu « Options ». Dans la fenêtre qui s’affiche, choisissez « Vie
          privée » et cliquez sur « supprimer des cookies spécifiques ». Repérez
          les fichiers qui contiennent le nom de la société exploitante.
          Sélectionnez les et supprimez les. La société exploitante vous invite
          à consulter le site de la CNIL à l’adresse suivante :
          https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser
        </p>
      </Modal>
    </div>
  );
};

export default MentionLegale;

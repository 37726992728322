import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useState } from "react";
import pasta1 from "./../../assets/img/formules/Pates/pate1.png";
import pasta2 from "./../../assets/img/formules/Pates/pate2.png";

function SlidePasta() {
  const [img] = useState([pasta1, pasta2]);

  return (
    <>
      <Carousel
        className="carouselSalad"
        showArrows={true}
        autoPlay={true}
        infiniteLoop={true}>
        {img.map((eachData, i) => {
          return (
            <div key={i} className="for2">
              <img src={eachData} alt="pasta" />
            </div>
          );
        })}
      </Carousel>{" "}
      {/* MODIFICATION REMI */}
      {/* MODIFICATION REMI */}
      {/* MODIFICATION REMI */}
      <p>Les pâtes</p>
      <p>Bolognaise / Carbonara / Végétarienne du moment 🌱</p>
      <p>Formule Entrée ou Dessert + Boisson</p>
      <p>Prix : 11€</p>
      {/* MODIFICATION REMI */}
      {/* MODIFICATION REMI */}
      {/* MODIFICATION REMI */}
    </>
  );
}

export default SlidePasta;

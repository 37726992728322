import horloge from "../../assets/img/lhorloge.png";
import pin from "../../assets/img/placer-localizer.png";
import tel from "../../assets/img/telephone.png";
import takeAway from "../../assets/img/takeaway.png";

function Blocs() {
  return (
    <div className="EachBlocs d-flex">
      <div className="bloc d-flex flex-column" data-aos="zoom-out">
        <img className="time" src={horloge} alt="horloge" />
        <p>
          Ouverture : Du Lundi au Samedi <br />
          7h-20h
        </p>
      </div>
      <div className="bloc2 d-flex flex-column" data-aos="zoom-out">
        <img src={pin} alt="pin" />
        <p>30 rue de Picpus | Paris 12 | Ligne 1 • Nation</p>
      </div>
      <div className="bloc d-flex flex-column " data-aos="zoom-out">
        <img className="emporter" src={takeAway} alt="emporter" />
      </div>
      <div className="bloc2 d-flex flex-column" data-aos="zoom-out">
        <img src={tel} alt="telephone" />
        <p>01.43.47.44.87</p>
      </div>
    </div>
  );
}

export default Blocs;

import SlideView from "./SlideView";
import team from "./../../assets/img/team.jpeg"

function Presentations() {
  return (
    <div className='presContainer d-flex flex-column justify-content-center align-items-center'>
        <h2 >Nos Valeurs</h2>
        <div className="photos d-flex">
          <SlideView/>
          <div className="photoTextContainer">
           
            <img className="team" src={team} alt="team" />
            <div className="texte" data-aos="zoom-in-up">
                <p className="textePresentation"> "La P’tite Cuisine est née d’une envie de créer un lieu convivial à la fois chaleureux et accueillant, où l’on proposerait des plats différents chaque jours et fait maison. Convaincue que la cuisine n’a pas de frontières, vous trouverez au sein de notre restaurant une cuisine aux inspirations provenant des quatre coins du monde à savourer aussi bien sur place qu’à emporter. "</p>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Presentations
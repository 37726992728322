import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import salad1 from "./../../assets/img/formules/Salades/salade1.png";
import salad2 from "./../../assets/img/formules/Salades/salade2.png";
import salad3 from "./../../assets/img/formules/Salades/salade3.png";
import salad4 from "./../../assets/img/formules/Salades/salade4.png";

function SlideSalade() {
  const [img] = useState([salad1, salad2, salad3, salad4]);

  return (
    <>
      <Carousel
        className="carouselSalad"
        showArrows={true}
        autoPlay={true}
        infiniteLoop={true}>
        {img.map((img, i) => {
          return <img key={i} src={img} alt="salade" />;
        })}
      </Carousel>
      <div className="textFormule1">
        {/* MODIFICATION REMI */}
        {/* MODIFICATION REMI */}
        {/* MODIFICATION REMI */}
        <p>La Salade composée du jour</p>
        <p>Formule Entrée ou Dessert + Boisson</p>
        <p>Prix : 12€</p>
        {/* MODIFICATION REMI */}
        {/* MODIFICATION REMI */}
        {/* MODIFICATION REMI */}
        <p
          className="saladeInsta"
          onClick={() =>
            window.open("https://www.instagram.com/laptite_cuisine/?hl=fr")
          }>
          {" "}
          <i className="fa-brands fa-instagram"></i> Venez découvrir nos salades
          du moment
        </p>{" "}
      </div>
    </>
  );
}

export default SlideSalade;

//il faut deplacer le texte pour pouvoir recuperer les infos ici

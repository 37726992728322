import React from "react";
import logo from "../../assets/img/logo.png";
import MentionLegale from "./MentionLegale";
function Footer() {
  return (
    <div className="footerContainer d-flex align-items-center">
      <div className="contacts d-flex flex-column">
        <div className="coordonnees">
          <h2>Contactez-nous !</h2>
          <p
            className="insta"
            onClick={() =>
              window.open("https://www.instagram.com/laptite_cuisine/?hl=fr")
            }>
            {" "}
            <i className=" insta fa-brands fa-instagram"></i> @laptite_cuisine
          </p>
          <b>
            <i className="fa-sharp fa-solid fa-phone"></i> 01.43.47.44.87
          </b>
        </div>
        <div className="avis">
          <h2>Donnez-nous votre avis</h2>
          <p
            onClick={() =>
              window.open(
                "https://www.tripadvisor.fr/Restaurant_Review-g187147-d7214385-Reviews-La_P_tite_Cuisine_de_Picpus-Paris_Ile_de_France.html"
              )
            }>
            TripAdvisor (5/5 ⭐)
          </p>
          <p
            onClick={() =>
              window.open(
                "https://www.google.com/search?q=la%20petite%20cuisine&rlz=1C5CHFA_enFR988FR989&sxsrf=AJOqlzVNyoGSf-WozUTxlhOcIjlbZDj1HA:1673787484903&ei=VPjDY_6pG93UkdUPmYanqAE&ved=2ahUKEwiH8N2j0Mn8AhUYVKQEHZK6BPsQvS56BAgUEAE&uact=5&oq=la+petite+cuisine&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzILCC4QgAQQxwEQrwEyBQgAEIAEMgQIABBDMgUIABCABDIFCAAQgAQyCwguEK8BEMcBEIAEMgYIABAHEB4yBQgAEIAEMgYIABAHEB4yCwguEIAEEMcBEK8BSgQIQRgASgQIRhgAUABYAGC9AWgAcAF4AIABXYgBXZIBATGYAQCgAQHAAQE&sclient=gws-wiz-serp&tbs=lf:1,lf_ui:9&tbm=lcl&rflfq=1&num=10&rldimm=15073250410276491373&lqi=ChFsYSBwZXRpdGUgY3Vpc2luZUiDl_3ShquAgAhaHRAAEAEQAhgAGAIiEWxhIHBldGl0ZSBjdWlzaW5lkgEKcmVzdGF1cmFudJoBI0NoWkRTVWhOTUc5blMwVkpRMEZuU1VOSGVrOWZVRWxCRUFFqgEZEAEqFSIRbGEgcGV0aXRlIGN1aXNpbmUoBA&phdesc=xuPr6EZEqq4&sa=X&rlst=f#lrd=0x47e67271fdbfffff:0xd12ef162fb0a906d,3,,,,&rlfi=hd:;si:15073250410276491373,l,ChFsYSBwZXRpdGUgY3Vpc2luZUiDl_3ShquAgAhaHRAAEAEQAhgAGAIiEWxhIHBldGl0ZSBjdWlzaW5lkgEKcmVzdGF1cmFudJoBI0NoWkRTVWhOTUc5blMwVkpRMEZuU1VOSGVrOWZVRWxCRUFFqgEZEAEqFSIRbGEgcGV0aXRlIGN1aXNpbmUoBA,y,xuPr6EZEqq4;mv:[[48.8540177,2.3980745999999997],[48.8178214,2.3413697]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:9"
              )
            }>
            <i className="fa-brands fa-google"></i>oogle (4.8/5 ⭐)
          </p>
        </div>
      </div>
      <div className="map d-flex flex-column justify-content-center align-items-center">
        <div className="googleMap">
          <iframe
            title="googlemap"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10502.679363427013!2d2.3950368!3d48.845436!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd12ef162fb0a906d!2sLa%20p&#39;tite%20cuisine%20de%20Picpus!5e0!3m2!1sfr!2sfr!4v1673777593481!5m2!1sfr!2sfr"
            width="500"
            height="250"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div className="adresse d-flex justify-content-center">
          <img className="logo" src={logo} alt="logo" />
        </div>
        <b>Ouverture : Du Lundi au Samedi • 7h-20h</b>
        <p
          className="adresseCuisine"
          onClick={() =>
            window.open(
              "https://www.google.com/maps?q=google+map+la+ptite+cuisine&rlz=1C5CHFA_enFR988FR989&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjQzu_Gs8n8AhWUTKQEHS1CA_8Q_AUoAXoECAEQAw"
            )
          }>
          30 rue de Picpus | Paris 12 | Ligne 1 • Nation
        </p>
        <h3
          className="signature"
          onClick={() =>
            window.open("https://jeanluctan-portfolio.netlify.app/")
          }>
          @2023 Designed & Built by <span>JLT</span>
        </h3>
        <MentionLegale />
      </div>
    </div>
  );
}

export default Footer;

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import poke1 from "./../../assets/img/formules/PokeBowl/pb1.png";
import poke2 from "./../../assets/img/formules/PokeBowl/pb2.png";
import poke3 from "./../../assets/img/formules/PokeBowl/pb3.png";
import poke4 from "./../../assets/img/formules/PokeBowl/pb4.png";
import { useState } from "react";

function SlidePoke() {
  const [img] = useState([poke1, poke2, poke3, poke4]);
  return (
    <>
      <Carousel
        className="carouselSalad"
        showArrows={true}
        autoPlay={true}
        infiniteLoop={true}>
        {img.map((img, i) => {
          return <img key={i} src={img} alt="pokes" />;
        })}
      </Carousel>
      <p>Poke Bowl / Plat du jour</p>
      <p>Formule Entrée ou Dessert + Boisson</p>
      <p>Prix : 13€</p>
      <p
        className="saladeInsta"
        onClick={() =>
          window.open("https://www.instagram.com/laptite_cuisine/?hl=fr")
        }>
        {" "}
        <i className="fa-brands fa-instagram"></i> Venez decouvrir nos plats du
        jour
      </p>{" "}
    </>
  );
}

export default SlidePoke;

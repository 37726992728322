import { useState } from "react";
import logo from "../../assets/img/logo.png";
import poke1 from "../../assets/img/plat2.png";
import poke2 from "../../assets/img/platViande.png";
import { Link } from "react-scroll";

function Header() {
  const [nav, setNav] = useState(false);

  return (
    <div className="header">
      <div className="headerContent d-flex">
        <div className="logoTitle d-flex">
          <img className="logo" src={logo} alt="logo" />
          <h1 className="accueil" data-aos="fade-right">
            LA P'TITE CUISINE de Picpus
          </h1>
        </div>
        <ul className="navBar d-flex" data-aos="fade-left">
          <Link
            to="header"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            delay={500}>
            <li>Accueil</li>
          </Link>
          <Link
            to="titreFormules"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            delay={500}>
            <li>Nos Formules</li>
          </Link>
          <Link
            to="presContainer"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            delay={500}>
            <li>Nos Valeurs</li>
          </Link>
          <Link
            to="footerContainer"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            delay={500}>
            <li>Contact</li>
          </Link>
        </ul>
        <i onClick={() => setNav(!nav)} className=" bars fa-solid fa-bars"></i>
        {nav && (
          <div className="navBarResponsive">
            <Link
              to="header"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
              delay={500}>
              <li>Accueil</li>
            </Link>
            <Link
              to="titreFormules"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
              delay={500}>
              <li>Nos Formules</li>
            </Link>
            <Link
              to="presContainer"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
              delay={500}>
              <li>Nos Valeurs</li>
            </Link>
            <Link
              to="footerContainer"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
              delay={500}>
              <li>Contact</li>
            </Link>
          </div>
        )}
      </div>
      <div className="accueil d-flex ">
        <div className="descriptions d-flex flex-column" data-aos="fade-down">
          <h2>Restauration Rapide 100% Fait Maison & Veggie Friendly 🌱</h2>
          <p>
            Dans notre restaurant, vous pourrez déguster des plats de cuisine du
            monde, du « fait maison » changeant tous les jours et certains plats
            convenant aux végétariens. N’hésitez pas à venir tester nos formules
            du midi !
          </p>
        </div>
        <div className="picPoke d-flex">
          <img className="pic1" src={poke1} alt="poke1" />
          <img className="pic2" src={poke2} alt="poke2" />
        </div>
      </div>
    </div>
  );
}

export default Header;
